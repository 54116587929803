import React from 'react';
import Loader from '../loader';
import './styles.scss';

export default function ButtonLoader({
  action,
  text,
  isLoading,
}) {
  let buttonOrLoader = <button type="submit" className="btn-gral" onClick={action}>{text}</button>;

  if (isLoading) {
    buttonOrLoader = <Loader inButton />;
  }

  return (
    buttonOrLoader
  );
}
