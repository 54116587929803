import React, { useState } from 'react';
import './styles.scss';
import { minutesToHours } from '../../utils/times';

function ProjectsTable({ items }) {
  const [expandedProjects, setExpandedProjects] = useState([]);
  const [expandedObjectives, setExpandedObjectives] = useState([]);
  let totalMinutes = 0;
  const projectMap = {};

  items.forEach((item) => {
    const {
      project,
      objective,
      person,
      minutes,
    } = item;

    if (!project || !person) {
      return;
    }

    totalMinutes += minutes;

    if (project.id in projectMap) {
      projectMap[project.id].totalMinutes += minutes;
      if (objective) {
        if (objective.id in projectMap[project.id].objectives) {
          projectMap[project.id].objectives[objective.id].totalMinutes += minutes;
          const personIndex = projectMap[project.id].objectives[objective.id].people.findIndex(
            (p) => p.id === person.id,
          );

          if (personIndex !== -1) {
            projectMap[project.id].objectives[objective.id].people[personIndex].minutes += minutes;
          } else {
            projectMap[project.id].objectives[objective.id].people.push({
              id: person.id,
              firstName: person.firstName,
              lastName: person.lastName,
              minutes,
            });
          }
        } else {
          projectMap[project.id].objectives[objective.id] = {
            id: objective.id,
            objectiveTitle: objective.title,
            totalMinutes: minutes,
            people: [
              {
                id: person.id,
                firstName: person.firstName,
                lastName: person.lastName,
                minutes,
              },
            ],
          };
        }
      } else {
        const personIndex = projectMap[project.id].people.findIndex(
          (p) => p.id === person.id,
        );

        if (personIndex !== -1) {
          projectMap[project.id].people[personIndex].minutes += minutes;
        } else {
          projectMap[project.id].people.push({
            id: person.id,
            firstName: person.firstName,
            lastName: person.lastName,
            minutes,
          });
        }
      }
    } else {
      projectMap[project.id] = {
        id: project.id,
        projectName: project.name,
        totalMinutes: minutes,
        people: objective ? [] : [
          {
            id: person.id,
            firstName: person.firstName,
            lastName: person.lastName,
            minutes,
          },
        ],
        objectives: objective ? {
          [objective.id]: {
            id: objective.id,
            objectiveTitle: objective.title,
            totalMinutes: minutes,
            people: [
              {
                id: person.id,
                firstName: person.firstName,
                lastName: person.lastName,
                minutes,
              },
            ],
          },
        } : {},
      };
    }
  });

  const projectArray = Object.values(projectMap).sort((a, b) => b.totalMinutes - a.totalMinutes);

  const handleClickProject = (id) => {
    setExpandedProjects((prevState) => (prevState.includes(id)
      ? prevState.filter((projectId) => projectId !== id)
      : [...prevState, id]));
  };

  const handleClickObjective = (id) => {
    setExpandedObjectives((prevState) => (prevState.includes(id)
      ? prevState.filter((objectiveId) => objectiveId !== id)
      : [...prevState, id]));
  };

  return (
    <div className="worked-times-projects-stats">
      <div className="table-container">
        <div className="title-container">
          <div className="project-tag project-external" />
          <h2 className="title-table">Horas cargadas por proyecto</h2>
        </div>
        <table>
          <thead>
            <tr className="project-row">
              <th>Proyecto</th>
              <th>Horas</th>
              <th>Porcentaje</th>
            </tr>
          </thead>
          <tbody>
            {projectArray.map((project) => (
              <React.Fragment key={project.id}>
                <tr className="project-row">
                  <td>
                    <div className="project-cell">
                      <div className="project-tag project-external" />
                      <button
                        type="button"
                        onClick={() => handleClickProject(project.id)}
                        className="expand-button"
                      >
                        {expandedProjects.includes(project.id) ? '-' : '+'}
                      </button>
                      <span className="project-name">{project.projectName}</span>
                    </div>
                  </td>
                  <td>{minutesToHours(project.totalMinutes)}</td>
                  <td>
                    {((project.totalMinutes * 100) / totalMinutes).toFixed(2)}
                    %
                  </td>
                </tr>
                {expandedProjects.includes(project.id) && (
                  <>
                    {project.people.map((person) => (
                      <tr key={person.id} className="project-person-row">
                        <td className="person-cell">
                          <span className="details-row">{`${person.firstName} ${person.lastName}`}</span>
                        </td>
                        <td>{minutesToHours(person.minutes)}</td>
                        <td>
                          {((person.minutes * 100) / project.totalMinutes).toFixed(2)}
                          %
                        </td>
                      </tr>
                    ))}
                    {Object.values(project.objectives)
                      .sort((a, b) => b.totalMinutes - a.totalMinutes)
                      .map((objective) => (
                        <React.Fragment key={objective.id}>
                          <tr className="objective-row">
                            <td>
                              <div className="objectives-cell">
                                <div className="objective-tag project-internal" />
                                <button
                                  type="button"
                                  onClick={() => handleClickObjective(objective.id)}
                                  className="expand-button"
                                >
                                  {expandedObjectives.includes(objective.id) ? '-' : '+'}
                                </button>
                                <span className="objective-title">{objective.objectiveTitle}</span>
                              </div>
                            </td>
                            <td>{minutesToHours(objective.totalMinutes)}</td>
                            <td>
                              {((objective.totalMinutes * 100) / project.totalMinutes).toFixed(2)}
                              %
                            </td>
                          </tr>
                          {expandedObjectives.includes(objective.id) && (
                            objective.people.map((person) => (
                              <tr key={person.id} className="objective-person-row">
                                <td className="person-cell">
                                  <span className="details-row">{`${person.firstName} ${person.lastName}`}</span>
                                </td>
                                <td>{minutesToHours(person.minutes)}</td>
                                <td>
                                  {((person.minutes * 100) / objective.totalMinutes).toFixed(2)}
                                  %
                                </td>
                              </tr>
                            ))
                          )}
                        </React.Fragment>
                      ))}
                  </>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ProjectsTable;
