import React, { useEffect, useState } from 'react';
import './styles.scss';
import swal from 'sweetalert';
import {
  dayOfWeekFromUTC,
  labelFromUTC,
  startOfDay,
  startOfWeek,
} from '../../utils/dates';
import { minutesToHours } from '../../utils/times';
import { getWorkedTimes } from '../../services/worked-times';
import Loader from '../loader';

export default function WeekHistory({
  person, force, selectDay, changeTextButton, dateFrom = startOfWeek(new Date()),
}) {
  const [loading, setLoading] = useState([]);
  const [minutesByDay, setMinutesByDay] = useState([0, 0, 0, 0, 0, 0, 0]);
  const weekDays = ['', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'];

  function updateDays() {
    const dateTo = new Date(dateFrom);
    dateTo.setDate(dateTo.getDate() + 6);
    setLoading(true);
    getWorkedTimes({
      personId: person,
      dateFrom,
      dateTo,
    })
      .then((results) => {
        const newDays = [0, 0, 0, 0, 0, 0, 0];
        results.forEach((elem) => {
          const dayOfWeek = dayOfWeekFromUTC(elem.date);
          newDays[dayOfWeek] += elem.minutes;
        });
        setMinutesByDay(newDays);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function checkIfExist(idx) {
    const newDate = new Date(dateFrom);
    newDate.setDate(newDate.getDate() + idx - 1);
    if (minutesByDay[idx] > 0) {
      swal({
        title: '¡ADVERTENCIA!',
        text: 'Seleccionaste un día que ya tiene horas cargadas.',
        buttons: ['Cancelar', 'Continuar'],
      })
        .then((resp) => {
          if (resp) {
            selectDay(newDate);
            changeTextButton('Sobreescribir');
          }
        });
    } else {
      selectDay(newDate);
      changeTextButton('Guardar');
    }
  }

  function dayClass(dayIdx) {
    const dayIdxDate = new Date(dateFrom);
    dayIdxDate.setDate(dayIdxDate.getDate() + dayIdx - 1);
    const currentDate = startOfDay(new Date());
    if (
      dayIdxDate.getDate() === currentDate.getDate()
      && dayIdxDate.getMonth() === currentDate.getMonth()
    ) {
      return 'today';
    }
    if (minutesByDay[dayIdx] >= 360) {
      return 'complete';
    }
    if (minutesByDay[dayIdx] === 0) {
      return 'no-charge';
    }
    return 'incomplete';
  }
  function renderRows() {
    return weekDays.map((elem, idx) => {
      if (idx === 0) {
        return null;
      }
      return (
        <div
          key={`day-box-${elem}`}
          className={`day-box ${dayClass(idx)}`}
          onClick={() => checkIfExist(idx)}
          aria-hidden="true"
        >
          <p>{elem}</p>
          <p className="right">{minutesToHours(minutesByDay[idx])}</p>
        </div>
      );
    });
  }

  useEffect(() => {
    if (person) {
      updateDays();
    }
  }, [person]);

  useEffect(() => {
    if (force && person) {
      updateDays();
    }
  }, [force]);

  if (!person) {
    return null;
  }

  if (loading) {
    return <div className="content-loader-week"><Loader /></div>;
  }

  return (
    <div className="historic">
      <h4>
        {`Semana del ${labelFromUTC(dateFrom, 'DD/MM')}`}
      </h4>
      <div className="days">
        {renderRows()}
      </div>
    </div>
  );
}
