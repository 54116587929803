import axios from 'axios';
import { getAuthorization } from '../utils/config-request';

const API_URL = process.env.REACT_APP_API_URL;

export const getPeople = () => {
  return axios
    .get(`${API_URL}api/people`, {
      headers: getAuthorization(),
    })
    .then((response) => {
      return response.data;
    });
};

export default getPeople;
