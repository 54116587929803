export function minutesToHours(minutes) {
  const hs = Math.floor(minutes / 60);
  const hoursLength = hs.toString().length;
  const lengthToParse = hoursLength > 2 ? hoursLength : 2;
  const min = minutes - (hs * 60);
  return `${`0${hs}`.slice(-lengthToParse)}:${`0${min}`.slice(-2)}`;
}

export default {
  minutesToHours,
};
