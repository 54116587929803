import React, {
  useContext,
  createContext,
  useMemo,
} from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext({});

export default function AuthProvider({ children }) {
  function setToken(newToken) {
    localStorage.setItem('token', newToken);
  }

  function unsetToken() {
    localStorage.removeItem('token');
  }

  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  function isLoggedIn() {
    const token = localStorage.getItem('token');
    if ((token && token !== '') && !isTokenExpired(token)) {
      return true;
    }
    return false;
  }

  function getDecodedToken() {
    const token = localStorage.getItem('token');
    return jwtDecode(token);
  }

  const authproviderdata = useMemo(() => ({
    setToken,
    unsetToken,
    isLoggedIn,
    getDecodedToken,
  }), []);

  return (
    <AuthContext.Provider value={authproviderdata}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
