import React from 'react';
import './styles.scss';

export default function InputSelect({
  options,
  selectOption,
  currentOption,
  placeholder = 'Elegir',
  labelFn = (option) => option.name,
}) {
  return (
    <div className="select-options">
      <select
        onChange={(evt) => selectOption(evt.target.value)}
        value={currentOption ? currentOption.id : 'select'}
      >
        <option value="select" className="placeholder">{placeholder}</option>
        {
          options.map((option) => (
            <option key={option.id} value={option.id}>
              {labelFn(option)}
            </option>
          ))
        }
      </select>
    </div>
  );
}
