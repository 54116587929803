import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './styles.scss';
import { useAuth } from '../../hooks/auth';
import logoutSvg from '../../assets/svg/logout.svg';

export default function Board() {
  const { unsetToken } = useAuth();
  const navigate = useNavigate();
  function logout() {
    unsetToken();
    navigate('/login');
  }
  return (
    <div className="nav-bar">
      <NavLink to="/board">
        <img src="assets/home.svg" alt="home" />
      </NavLink>
      <NavLink to="/">
        <img src="assets/hours.svg" alt="hours" />
      </NavLink>
      <button type="button" onClick={logout}>
        <img src={logoutSvg} alt="logout" />
        <p>Cerrar Sesión</p>
      </button>
    </div>
  );
}
