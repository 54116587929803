import React from 'react';
import './styles.scss';

function handleKeyPress(event) {
  const { value } = event.target;
  if (event.key === 'Backspace') {
    return;
  }
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
  if (value.length >= 2) {
    event.preventDefault();
  }
}

export default function CustomButton({ changeHours }) {
  return (
    <input type="number" min="0" max="99" placeholder="Otro" onKeyDown={handleKeyPress} onChange={(evt) => changeHours(evt.target.value)} />
  );
}
