import axios from 'axios';
import { getAuthorization } from '../utils/config-request';

const API_URL = process.env.REACT_APP_API_URL;

export const getObjectives = (personId) => {
  return axios
    .get(`${API_URL}api/objectives`, {
      headers: getAuthorization(),
      params: { personId },
    })
    .then((response) => {
      return response.data;
    });
};

export default getObjectives;
