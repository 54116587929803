import React, { useEffect, useState, useCallback } from 'react';
import './styles.scss';
import CustomButton from '../time-button/custom-button';

export default function TimeSelector({
  label,
  asociatedProject,
  onChange,
  deleteEntry,
}) {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    onChange(hours * 60 + minutes);
  }, [hours, minutes]);

  const changeHours = useCallback((newValue) => {
    setHours(newValue);
  }, []);

  const changeMinutes = useCallback((newValue) => {
    setMinutes(newValue);
  }, []);

  return (
    <div className="comp-time-selector">
      <svg
        onClick={deleteEntry}
        fill="currentColor"
        viewBox="0 0 16 16"
        height="1em"
        width="1em"
      >
        <path d="M5.5 5.5A.5.5 0 016 6v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V6z" />
        <path
          fillRule="evenodd"
          d="M14.5 3a1 1 0 01-1 1H13v9a2 2 0 01-2 2H5a2 2 0 01-2-2V4h-.5a1 1 0 01-1-1V2a1 1 0 011-1H6a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM4.118 4L4 4.059V13a1 1 0 001 1h6a1 1 0 001-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
        />
      </svg>
      <div className="project-name">
        <span>{asociatedProject || label}</span>
        {asociatedProject && (
        <p>{label}</p>
        )}
        <h4>{`${hours || '00'}:${minutes || '00'}`}</h4>
      </div>
      <div className="time-selector">
        <div className="horas-label">Horas</div>
        <div className="hours">
          {
            [0, 1, 2, 3, 4, 5, 6].map((option) => {
              return (
                <button
                  key={`hour-${option}`}
                  className={`time-button ${hours === option ? 'active' : ''}`}
                  type="button"
                  onClick={() => changeHours(option)}
                >
                  {option}
                </button>
              );
            })
          }
          <CustomButton changeHours={changeHours} changeMinutes={changeMinutes} />
        </div>
        <div className="minutos-label">Minutos</div>
        <div className="minutes">
          {
            [0, 10, 20, 30, 40, 50].map((option) => {
              return (
                <button
                  key={`minute-${option}`}
                  className={`time-button ${minutes === option ? 'active' : ''}`}
                  type="button"
                  onClick={() => changeMinutes(option)}
                >
                  {option}
                </button>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}
