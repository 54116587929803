export function startOfDay(initialDate) {
  const date = new Date(initialDate);
  date.setUTCHours(0, 0, 0, 0);
  return date;
}

export function startOfWeek(initialDate) {
  const date = new Date(initialDate);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  date.setDate(diff);
  return startOfDay(date);
}

export function startOfMonth(initialDate) {
  const date = new Date(initialDate);
  date.setDate(1);
  return startOfDay(date);
}

export function startOfQuarter(initialDate) {
  const date = new Date(initialDate);
  date.setDate(1);
  date.setMonth(date.getMonth() - 2);
  return startOfDay(date);
}

export function startOfLastMonth(initialDate) {
  const date = new Date(initialDate);
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);
  return startOfDay(date);
}

export function endOfLastMonth(initialDate) {
  const date = new Date(initialDate);
  date.setDate(0);
  return startOfDay(date);
}

export function startOfLastWeek(initialDate) {
  const date = new Date(initialDate);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  date.setDate(diff - 7);
  return startOfDay(date);
}

export function endOfLastWeek(initialDate) {
  const date = new Date(initialDate);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  date.setDate(diff - 1);
  return startOfDay(date);
}

export function labelFromUTC(date, format) {
  const dateArg = new Date(date);
  dateArg.setHours(dateArg.getHours() + 3);

  const fullYear = dateArg.getFullYear();
  const month = (`0${(dateArg.getMonth() + 1)}`).slice(-2);
  const day = (`0${dateArg.getDate()}`).slice(-2);
  let result = '';
  switch (format) {
    case 'YYYY-MM-DD':
      result = `${fullYear}-${month}-${day}`;
      break;
    case 'DD/MM':
      result = `${day}/${month}`;
      break;
    default:
      break;
  }

  return result;
}

export function dayOfWeekFromUTC(date) {
  const dateArg = new Date(date);
  dateArg.setHours(dateArg.getHours() + 3);
  return dateArg.getDay();
}

export default {
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfLastMonth,
  startOfLastWeek,
  endOfLastWeek,
  endOfLastMonth,
  labelFromUTC,
  dayOfWeekFromUTC,
};
