import './styles.scss';
import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import { getPeople } from '../../services/people';
import { getProjects } from '../../services/projects';
import { getObjectives } from '../../services/objectives';
import { saveWorkedTime } from '../../services/worked-times';
import { dayOfWeekFromUTC, labelFromUTC, startOfWeek } from '../../utils/dates';
import Loader from '../../components/loader';
import ResumeBox from '../../components/resume-box';
import WeekHistory from '../../components/week-history';
import TimeSelector from '../../components/time-selector';
import InputSelect from '../../components/input-select';
import NavBar from '../../components/nav-bar';
import { useAuth } from '../../hooks/auth';

export default function LoadWorkedTime() {
  const [projects, setProjects] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [currentObjective, setCurrentObjective] = useState(null);
  const [textButton, setTextButton] = useState('Guardar');
  const [saving, setSaving] = useState(false);
  const [people, setPeople] = useState([]);
  const [entries, setEntries] = useState([]);
  const [personId, setPersonId] = useState();
  const [date, setDate] = useState(new Date());
  const [forceReloadWeekHistory, setForceReloadWeekHistory] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const { isLoggedIn } = useAuth();
  const lastWeekMonday = startOfWeek(new Date());
  lastWeekMonday.setDate(lastWeekMonday.getDate() - 7);

  useEffect(() => {
    if (!isLoggedIn()) {
      <Navigate replace to="/login" />;
    }
    getPeople()
      .then((res) => {
        setPeople(res);
      });
  }, []);

  function checkDay() {
    if (date) {
      const dayOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
      return dayOfWeek[dayOfWeekFromUTC(date)];
    }
    return '-';
  }

  useEffect(() => {
    getProjects()
      .then((res) => {
        setProjects(res);
      });
  }, []);

  useEffect(() => {
    if (people && localStorage.getItem('personId')) {
      const foundPerson = people.find((person) => person.id === parseInt(localStorage.getItem('personId'), 10));
      if (foundPerson) {
        setPersonId(foundPerson.id);
      }
    }
  }, [people]);

  useEffect(() => {
    if (personId) {
      getObjectives(personId)
        .then((res) => {
          setObjectives(res);
        })
        .catch((error) => {
          toast.error('Error fetching objectives:', error);
        });
    } else {
      setObjectives([]);
    }
  }, [personId]);

  const selectPerson = useCallback((evt) => {
    const selectedPersonId = parseInt(evt.target.value, 10);
    setPersonId(selectedPersonId);
    const person = people.find((p) => p.id === selectedPersonId);
    if (person) localStorage.setItem('personId', person.id);
    setEntries([]);
    setCurrentProject(null);
    setCurrentObjective(null);
  }, [people]);

  function selectedDay(evt) {
    setDate(evt);
  }

  function handleSelection(id, type) {
    const isProject = type === 'project';
    const selectedItem = isProject
      ? projects.find((elem) => elem.id.toString() === id)
      : objectives.find((elem) => elem.id.toString() === id);
    if (!selectedItem) return;

    const current = isProject ? currentProject : currentObjective;
    const setCurrent = isProject ? setCurrentProject : setCurrentObjective;

    if (selectedItem.id === current?.id) {
      setCurrent(null);
    } else {
      setCurrent(selectedItem);
    }

    const isInEntries = entries.some((elem) => (
      isProject
        ? elem.projectId === selectedItem.id && !elem.objectiveId
        : elem.objectiveId === selectedItem.id
    ));

    if (isInEntries) return;

    const entryValue = isProject
      ? { projectId: selectedItem.id, projectName: selectedItem.name, minutes: 0 }
      : {
        objectiveId: selectedItem.id,
        projectId: selectedItem.project.id,
        objectiveTitle: selectedItem.title,
        asociatedProject: selectedItem.project.name,
        minutes: 0,
      };

    setEntries((prevEntries) => [...prevEntries, entryValue]);
  }

  function selectProject(projectId) {
    handleSelection(projectId, 'project');
  }

  function selectObjective(objectiveId) {
    handleSelection(objectiveId, 'objective');
  }

  function deleteEntry(id, type) {
    setEntries((prevEntries) => prevEntries.filter((entry) => {
      if (type === 'project') {
        return entry.projectId !== id || entry.objectiveId;
      }
      return entry.objectiveId !== id;
    }));
  }

  const changeMinutes = (id, minutes, type) => {
    setEntries((prevEntries) => {
      return prevEntries.map((entry) => {
        if (type === 'project' && id === entry.projectId && !entry.objectiveId) {
          return {
            ...entry,
            minutes,
          };
        }
        if (type === 'objective' && id === entry.objectiveId) {
          return {
            ...entry,
            minutes,
          };
        }
        return entry;
      });
    });
  };

  const submit = useCallback(() => {
    setSaving(true);
    saveWorkedTime({
      personId,
      date,
      entries: entries.map((e) => ({
        projectId: e.projectId,
        objectiveId: e.objectiveId,
        minutes: e.minutes,
      })),
    })
      .then(() => {
        toast.success('Horas cargadas!');
        setEntries([]);
        setForceReloadWeekHistory(true);
        setCurrentProject(null);
        setTimeout(() => {
          setSaving(false);
          setForceReloadWeekHistory(false);
        }, 500);
      })
      .catch((error) => {
        setSaving(false);
        toast.error(error.response ? `Hubo un error! ${error.response.data.code}` : 'Mmm algo raro pasó, hablá con Lautaro');
      });
  }, [personId, date, entries]);

  function getTotalMinutes() {
    let totalMinutes = 0;
    entries.forEach((elem) => {
      totalMinutes += elem.minutes;
    });
    return totalMinutes;
  }

  const renderTime = useCallback(() => {
    let totalminutes = getTotalMinutes();
    let totalhours = 0;
    while (totalminutes >= 60) {
      totalminutes -= 60;
      totalhours += 1;
    }
    return { hours: totalhours, minutes: totalminutes };
  }, [getTotalMinutes]);

  const projectsQuant = useCallback(() => {
    return entries.length;
  }, [entries]);

  const handleSelectObjective = useCallback((id) => {
    selectObjective(id);
  }, [selectObjective]);

  const handleSelectProject = useCallback((id) => {
    selectProject(id);
  }, [selectProject]);

  const renderFirstOption = useCallback(() => {
    const storedPersonId = parseInt(localStorage.getItem('personId'), 10);
    const person = people.find((p) => p.id === storedPersonId);
    return person
      ? <option value={person.id} key={`person-${person.id}`}>{`${person.firstName} ${person.lastName}`}</option>
      : <option value={null}>Elegir</option>;
  }, [people]);

  if (!people.length || !projects.length) {
    return <Loader />;
  }

  const sortedObjectives = objectives.sort((a, b) => {
    if (a.project.name < b.project.name) return -1;
    if (a.project.name > b.project.name) return 1;
    return 0;
  });

  return (
    <>
      <NavBar />
      <div className="load-worked-time">
        <div className="content-data-left">
          <h2>CARGA DE HORAS LABORALES</h2>
          <div className="box-form">
            <label htmlFor="personInput">
              Selecciona Nombre
              <select id="personInput" onChange={selectPerson}>
                {renderFirstOption()}
                {people.map((person) => {
                  if (person.id === personId) {
                    return null;
                  }
                  return (
                    <option value={person.id} key={`person-${person.id}`}>
                      {`${person.firstName} ${person.lastName}`}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="history-box">
            <WeekHistory
              dateFrom={lastWeekMonday}
              person={personId}
              force={forceReloadWeekHistory}
              selectDay={(dat) => selectedDay(dat)}
              changeTextButton={(text) => setTextButton(text)}
            />
            <WeekHistory
              person={personId}
              force={forceReloadWeekHistory}
              selectDay={(dat) => selectedDay(dat)}
              changeTextButton={(text) => setTextButton(text)}
            />
          </div>
        </div>
        <div className="project-data-right">
          <h3>
            {`Día: ${checkDay()} ${labelFromUTC(date, 'DD/MM')}`}
          </h3>
          <div className="grid-box-projects">
            <div>
              <div className="box-form">
                <div className="objectives">
                  <span>Mis objetivos</span>
                  <InputSelect
                    options={sortedObjectives}
                    selectOption={handleSelectObjective}
                    currentOption={currentObjective}
                    placeholder="Seleccionar objetivo"
                    labelFn={(objective) => `${objective.project.name} - ${objective.title}`}
                  />
                </div>
                <div className="external-projects">
                  <span>Proyectos externos</span>
                  <InputSelect
                    options={projects.filter((project) => project.internal === false)}
                    selectOption={handleSelectProject}
                    currentOption={currentProject}
                    placeholder="Seleccionar proyecto externo"
                    labelFn={(project) => project.name}
                  />
                </div>
                <div className="internal-projects">
                  <span>Proyectos internos</span>
                  <InputSelect
                    options={projects.filter((project) => project.internal === true)}
                    selectOption={handleSelectProject}
                    currentOption={currentProject}
                    placeholder="Seleccionar proyecto externo"
                    labelFn={(project) => project.name}
                  />
                </div>
              </div>
            </div>
            <div className="project-info">
              <div className="project-time">
                {entries.length === 0 ? (
                  <h2>No hay proyectos u objetivos seleccionados</h2>
                ) : (
                  entries.map((entry) => {
                    const key = `time-sel-${entry.projectId || ''}-${entry.objectiveId || ''}`;
                    return (
                      <TimeSelector
                        key={key}
                        label={entry.projectName || entry.objectiveTitle}
                        asociatedProject={entry.asociatedProject || null}
                        onChange={(value) => {
                          const id = entry.objectiveId || entry.projectId;
                          const type = entry.objectiveId ? 'objective' : 'project';
                          changeMinutes(id, value, type);
                        }}
                        deleteEntry={() => deleteEntry(entry.objectiveId || entry.projectId, entry.objectiveId ? 'objective' : 'project')}
                      />
                    );
                  })
                )}
              </div>
            </div>
          </div>

          <div className="clearfix" />
          <ResumeBox
            projectsQuant={projectsQuant}
            renderTime={renderTime}
            saving={saving}
            submit={submit}
            textButton={textButton}
          />
        </div>
      </div>
    </>
  );
}
