import React from 'react';
import './styles.scss';

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="content-footer">
        <p>
          {`Grava ${year} ♥`}
        </p>
      </div>
    </footer>
  );
}
