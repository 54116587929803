import React from 'react';
import './styles.scss';
import { minutesToHours } from '../../utils/times';

export default function WorkedTimesInternalExternal({ items }) {
  const internal = {
    minutes: 0,
    average: 0,
  };
  const external = {
    minutes: 0,
    average: 0,
  };
  let totalMinutes = 0;

  items.forEach((elem) => {
    totalMinutes += elem.minutes;
    if (elem.project.type === 'interno') {
      internal.minutes += elem.minutes;
    } else {
      external.minutes += elem.minutes;
    }
  });

  internal.average = ((internal.minutes * 100) / totalMinutes).toFixed(2);
  external.average = ((external.minutes * 100) / totalMinutes).toFixed(2);

  return (
    <div className="worked-times-projects-stats">
      <div className="chart-container">
        <h2 className="title-chart"> Horas internas y externas</h2>
        <div className="chart">
          <div className="chart-item chart-external" style={{ width: `${external.average}%` }}>
            <div className="chart-label">{`${external.average}%`}</div>
          </div>
          <div className="chart-item chart-internal" style={{ width: `${internal.average}%` }}>
            <div className="chart-label">{`${internal.average}%`}</div>
          </div>
        </div>
        <div className="chart-legend">
          <div className="chart-legend-item chart-external">
            <h3>Externas </h3>
            <p>{`${minutesToHours(external.minutes)} hs`}</p>
          </div>
          <div className="chart-legend-item chart-internal">
            <h3>Internas</h3>
            <p>{`${minutesToHours(internal.minutes)} hs`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
