import React from 'react';
import './styles.scss';

export default function Loader({ inButton }) {
  let style = {};
  let styleButton = {};

  if (inButton) {
    style = {
      height: '100%',
      width: '100%',
    };
    styleButton = {
      height: '50px',
    };
  }

  return (
    <div style={style} className="content-loader">
      <img style={styleButton} src="assets/loader.svg" alt="loader" />
    </div>
  );
}
