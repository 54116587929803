import axios from 'axios';
import { getAuthorization } from '../utils/config-request';

const API_URL = process.env.REACT_APP_API_URL;

export const saveWorkedTime = (body) => {
  return axios
    .post(`${API_URL}api/workedtimes`, body, {
      headers: getAuthorization(),
    })
    .then((response) => {
      return response.data;
    });
};

export const getWorkedTimes = (filters) => {
  return axios({
    method: 'get',
    url: `${API_URL}api/workedtimes`,
    params: filters,
    headers: getAuthorization(),
  })
    .then((response) => {
      return response.data.map((elem) => {
        const newElem = elem;
        newElem.date = new Date(elem.date);
        return newElem;
      });
    });
};

export const downloadWorkedTimesCsv = (filters) => {
  return axios({
    method: 'get',
    url: `${API_URL}api/export/workedtimes`,
    params: filters,
    responseType: 'blob',
    headers: getAuthorization(),
  })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data]),
      );
      const link = document.createElement('a');
      link.href = url;

      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${day}_${month}_${year}`;

      link.setAttribute(
        'download',
        `workedtimes_${formattedDate}.csv`,
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
};

export default saveWorkedTime;
