import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './hooks/auth';

export default function PrivateRoute({ children }) {
  const { isLoggedIn } = useAuth();
  if (!isLoggedIn()) {
    return <Navigate replace to="/login" />;
  }

  return children;
}
