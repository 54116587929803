import React from 'react';
import './styles.scss';

function ResumeBox({
  projectsQuant,
  renderTime,
  saving,
  submit,
  textButton,
}) {
  return (
    <div className="last-box">
      <div className="resume">
        <h4>Total</h4>
        <div className="box-resume">
          Seleccionados
          <span>{projectsQuant()}</span>
        </div>
        <div className="box-resume">
          Horas
          <span>{`${renderTime().hours || '00'}:${renderTime().minutes || '00'}`}</span>
        </div>
      </div>
      <div className="button-side">
        {!saving && <button type="button" className="submit-btn btn-gral" onClick={submit}>{textButton}</button>}
      </div>
    </div>
  );
}

export default ResumeBox;
