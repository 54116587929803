import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login as loginService } from '../../services/authentication';
import Loader from '../../components/loader';
import './styles.scss';
import ButtonLoader from '../../components/button-loader';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function login(e) {
    e.preventDefault();
    setIsLoading(true);
    return loginService(username, password)
      .then((resp) => {
        if (resp && resp.error && resp.error.status !== 200) {
          if (resp.error.data.code === 'missing_parameters') {
            toast.error('Campos incompletos');
          } else if (resp.error.data.code === 'invalid_authentication') {
            toast.error('Usuario o contraseña invalido');
          } else {
            toast.error('Hubo un error!');
          }
          return setIsLoading(false);
        }
        setUsername('');
        setPassword('');
        return navigate('/');
      });
  }

  return (
    <div className="container">
      <div className="hidden">
        <Loader />
      </div>
      <h1 className="login-title">Grava Horas</h1>
      <div className="login">
        <form className="login-box" onSubmit={login}>
          <div className="line-form">
            <span>Usuario:</span>
            <input
              type="text"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div className="line-form">
            <span>Contraseña:</span>
            <input
              type="password"
              autoComplete="new-password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="line-button">
            <ButtonLoader action={() => login} text="Iniciar Sesión" isLoading={isLoading} />
          </div>
        </form>
      </div>
    </div>
  );
}
